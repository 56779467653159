import { createReducer, on } from '@ngrx/store';
import { Monitoring } from './monitoring.models';
import {
  CreateMonitoringFailure,
  CreateMonitoringSuccess,
  GetAllMonitoring,
  GetMonitoring,
  createMonitoring,
  deleteMonitoringSuccess,
  getAllMonitoringsFailure,
  getAllMonitoringsSuccess,
  getMonitoringsFailure,
  getMonitoringsSuccess,
  resetState,
  resetgetMonitoringState,
  updateMonitoringSuccess,
} from './monitoring.actions';

export interface MonitoringState {
  Monitoring: Monitoring | null;
  createMonitoringError: any;
  getMonitoringResponse: any;
  createMonitoringResponse: any;
  updateMonitoringResponse: any;
  deleteMonitoringResponse: any;
  getAllMonitoringResponse: any;
}

const initialState: MonitoringState = {
  Monitoring: null,
  createMonitoringError: null,
  getMonitoringResponse: null,
  createMonitoringResponse: null,
  updateMonitoringResponse: null,
  deleteMonitoringResponse: null,
  getAllMonitoringResponse: null,
};

export const monitoringReducer = createReducer(
  initialState,
  on(GetMonitoring, (state) => ({ ...state })),
  on(createMonitoring, (state) => ({ ...state, error: null })),
  on(CreateMonitoringSuccess, (state, { createMonitoringResponse }) => ({
    ...state,
    createMonitoringResponse,
  })),
  on(CreateMonitoringFailure, (state, { createMonitoringError }) => ({
    ...state,
    createMonitoringError,
  })),
  on(updateMonitoringSuccess, (state, { updateMonitoringResponse }) => ({
    ...state,
    updateMonitoringResponse,
  })),
  on(deleteMonitoringSuccess, (state, { deleteMonitoringResponse }) => ({
    ...state,
    deleteMonitoringResponse,
  })),

  on(getMonitoringsSuccess, (state, { getMonitoringResponse }) => ({
    ...state,
    getMonitoringResponse,
  })),
  on(getMonitoringsFailure, (state, { getMonitoringError }) => ({
    ...state,
    getMonitoringError,
  })),
  on(GetAllMonitoring, (state) => ({ ...state })),
  on(getAllMonitoringsSuccess, (state, { getAllMonitoringResponse }) => ({
    ...state,
    getAllMonitoringResponse,
  })),
  on(getAllMonitoringsFailure, (state, { getAllMonitoringError }) => ({
    ...state,
    getAllMonitoringError,
  })),

  on(resetState, (state) => ({
    ...state,
    getAllMonitoringResponse: null // Resetting this specific property
  })),


  on(resetgetMonitoringState, (state) => ({
    ...state,
    getAllMonitoringResponse: null // Resetting this specific property
  })),
)
