import { UtilitiesEffects } from './store/Utilities/utilities.effects';
import { MastersModuleEffects } from './store/Masters/masters.effects';
import { LOCALE_ID, NgModule } from '@angular/core';
import {
  BrowserModule,
  provideClientHydration,
} from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutsModule } from './layouts/layouts.module';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { SharedModule } from './shared/shared.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { registerLocaleData } from '@angular/common';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import localeIn from '@angular/common/locales/en-IN';
import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from '@angular/common/http';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { rootReducer } from './store';

//import { provideFirestore, getFirestore } from '@angular/fire/firestore';
//import { getAuth, provideAuth } from '@angular/fire/auth';
//import { getMessaging, provideMessaging } from '@angular/fire/messaging';

import { AuthenticationEffects } from './store/Authentication/authentication.effects';
import { provideFirebaseApp, getApp, initializeApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire/compat'; // Import AngularFireModule
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { FaqEffects } from './store/Faq/faq.effects';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FileUploadInterceptor } from './core/interceptors/fileprogress.interceptor';
import { SuppliersModuleEffects } from './store/Suppliers/suppliers.effects';
import { projectModuleEffects } from './store/Projects/projects.effects';
import { userslistEffects } from '../app/store/UserList/userlist.effect';
import { gatewayEffect } from './store/GateWay/gateway.effect';
import { MonitoringsModuleEffects } from './store/Monitoring/monitoring.effects';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { ExcelModule } from '@progress/kendo-angular-grid';
import { ConsumerEffects } from './store/customer/consumer.effects';
import { residentialUnitEffects } from './store/residential-unit/residentialUnit.effect';
import { UmdModuleEffects } from './store/Umd/umd.effects';
import { meterModuleEffects } from './store/meters/meters.effects';
import { WalletsEffects } from './store/Wallet/wallet.effect';
import { billsEffects } from './store/Bills/bills.effects';
import { billSettingsEffects } from './store/billsSettings/billSettings.effects';
import { DashBoardEffects } from './store/dashboard/dashboard.effects';
import { MappingEffects } from './store/mapping/mapping.effects';
import { CurrencyPipe } from '@angular/common';
export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
registerLocaleData(localeIn, 'en-IN');
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'app-root' }),  // Add this line,
    AppRoutingModule,
    LayoutsModule,
    PDFExportModule,
    CoreModule,
    HttpClientModule,
    FeaturesModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    TooltipModule,
    SharedModule.forRoot(),
    EffectsModule.forRoot([]),
    StoreModule.forRoot(rootReducer),
    EffectsModule.forRoot([
      AuthenticationEffects,
      FaqEffects,
      MastersModuleEffects,
      SuppliersModuleEffects,
      projectModuleEffects,
      userslistEffects,
      gatewayEffect,
      MonitoringsModuleEffects,
      ConsumerEffects,
      residentialUnitEffects,
      ConsumerEffects,
      UmdModuleEffects,
      UtilitiesEffects,
      meterModuleEffects,
      WalletsEffects,
      billsEffects,
      billSettingsEffects,
      DashBoardEffects,
      MappingEffects,
    ]),
    AngularFireModule.initializeApp(environment.firebaseConfig), // Initialize AngularFireModule with your Firebase configuration
    AngularFireMessagingModule,
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    // NgbModule
    PDFExportModule,
    ExcelModule,
  ],
  providers: [CurrencyPipe,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: FileUploadInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'en-IN' },
    provideClientHydration(),
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
