import { Injectable, Inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  map,
  switchMap,
  catchError,
  exhaustMap,
  tap,
  first,
} from 'rxjs/operators';
import { from, of } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '../../shared/shared.service';
import { SweetAlertIcon } from '../../shared/constants/enum';
import { LoaderService } from '../../core/services/loader.service';
import { finalize } from 'rxjs/operators';
import { Validations } from '../../shared/constants/validations';
import {
  getAdminDashboard,
  getAdminDashboardFailure,
  getAdminDashboardSuccess,

  getAdminFlatwiseConsmptionsDashboard,

  getAdminFlatwiseConsmptionsDashboardFailure,

  getAdminFlatwiseConsmptionsDashboardSuccess,

  getConumerConnectionsData,

  getConumerConnectionsDataFailure,

  getConumerConnectionsDataSuccess,

  getConumerPostpaidConsumptionDashboard,

  getConumerPostpaidConsumptionDashboardFailure,

  getConumerPostpaidConsumptionDashboardSuccess,

  getConumerPostpaidDashboard,

  getConumerPostpaidDashboardFailure,

  getConumerPostpaidDashboardSuccess,

  getSuperAdminDashboard,
  getSuperAdminDashboardFailure,
  getSuperAdminDashboardRevenuDetials,
  getSuperAdminDashboardRevenuDetials_BasedOn_Date,
  getSuperAdminDashboardRevenuDetials_BasedOn_DateFailure,
  getSuperAdminDashboardRevenuDetials_BasedOn_DateSuccess,
  getSuperAdminDashboardRevenueFailure,
  getSuperAdminDashboardRevenueSuccess,
  getSuperAdminDashboardSuccess,
} from './dashboard.actions';
import { DashboardService } from '../../core/services/dashboard.service';

@Injectable()
export class DashBoardEffects {
  ServiceError = Validations.ServiceError;

  constructor(
    private actions$: Actions,
    private sharedService: SharedService,
    private router: Router,
    private loaderService: LoaderService,
    private dashboardService: DashboardService
  ) { }

  getSuperAdminDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSuperAdminDashboard),
      exhaustMap((projectList) => {

        console.log(projectList);
        
        this.loaderService.setLoading(true);
        return this.dashboardService.getSuperAdminDashboardData(projectList?.projectsList).pipe(
          map((response: any) => {
           
            console.log(response);
            if (response && response.statusCode === 200) {
              // this.loaderService.setLoading(false);
              return getSuperAdminDashboardSuccess({
                getSuperadmindashboardResponse: response,
              });
            } else {
              // this.loaderService.setLoading(false);
              return getSuperAdminDashboardFailure({
                getSuperadmindashboardResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getSuperAdminDashboardFailure({
                getSuperadmindashboardResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getAdminDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminDashboard),
      exhaustMap(({ ProjectId }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getAdminDashboardData(ProjectId).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getAdminDashboardSuccess({
                getadmindashboardResponse: response,
              });
            } else {
              return getAdminDashboardFailure({
                getadmindashboardResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getAdminDashboardFailure({
                getadmindashboardResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );


  getConumerPostpaidDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConumerPostpaidDashboard),
      exhaustMap(({ consumerdata }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getconsumerpostpaidDashboard(consumerdata).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getConumerPostpaidDashboardSuccess({
                getConumerPostpaidResponse: response,
              });
            } else {
              return getConumerPostpaidDashboardFailure({
                getConumerPostpaidResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getConumerPostpaidDashboardFailure({
                getConumerPostpaidResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );


  getConumerPostpaidConsumptionDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConumerPostpaidConsumptionDashboard),
      exhaustMap(({ consumerdata }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getconsumerpostpaidConsumptionDashboard(consumerdata).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getConumerPostpaidConsumptionDashboardSuccess({
                getConumerPostpaidConsumptionResponse: response,
              });
            } else {
              return getConumerPostpaidConsumptionDashboardFailure({
                getConumerPostpaidConsumptionResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getConumerPostpaidConsumptionDashboardFailure({
                getConumerPostpaidConsumptionResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );


  getConumerConnectionsData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getConumerConnectionsData),
      exhaustMap(({ consumerdata }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getconsumerConnectionsData(consumerdata).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getConumerConnectionsDataSuccess({
                getConumerConnectionsDataResponse: response,
              });
            } else {
              return getConumerConnectionsDataFailure({
                getConumerConnectionsDataResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getConumerConnectionsDataFailure({
                getConumerConnectionsDataResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getAdminFlatwiseConsmptionsDashboard$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdminFlatwiseConsmptionsDashboard),
      exhaustMap(({ flatwiseConsmptions }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getAdminFaltwiseConsumptionDashboardData(flatwiseConsmptions).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getAdminFlatwiseConsmptionsDashboardSuccess({
                getadminFlatwiseConsmptionsdashboardResponse: response,
              });
            } else {
              return getAdminFlatwiseConsmptionsDashboardFailure({
                getadminTopConsmptionsdashboardResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getAdminFlatwiseConsmptionsDashboardFailure({
                getadminTopConsmptionsdashboardResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );









  getSuperAdminDashboardRevenue$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSuperAdminDashboardRevenuDetials),
      exhaustMap((projectList) => {
        this.loaderService.setLoading(true);
        return this.dashboardService.getSuperAdminDashboardRevenueData(projectList?.projectsList).pipe(
          map((response: any) => {
            this.loaderService.setLoading(false);
            console.log(response);
            if (response && response.statusCode === 200) {
              return getSuperAdminDashboardRevenueSuccess({
                getSuperadmindashboardRevenueResponse: response,
              });
            } else {
              return getSuperAdminDashboardRevenueFailure({
                getSuperadmindashboardRevenueResponseError: response,
              });
            }
          }),
          catchError((error) => {
            console.log(error);
            this.sharedService.showMessageDialog(
              error.error.message,
              this.ServiceError.Error,
              SweetAlertIcon.ERROR
            );
            return of(
              getSuperAdminDashboardRevenueFailure({
                getSuperadmindashboardRevenueResponseError: error,
              })
            );
          }),
          finalize(() => this.loaderService.setLoading(false))
        );
      })
    )
  );

  getSuperAdminDashboardRevenue_BasedOnDate$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getSuperAdminDashboardRevenuDetials_BasedOn_Date),
      exhaustMap(({ daterange }) => {
        this.loaderService.setLoading(true);
        return this.dashboardService
          .getSuperAdminDashboardRevenueDataOnDate(daterange)
          .pipe(
            map((response: any) => {
              this.loaderService.setLoading(false);
              console.log(response);
              if (response && response.statusCode === 200) {
                return getSuperAdminDashboardRevenuDetials_BasedOn_DateSuccess({
                  getSuperadmindashboardRevenueOnDateResponse: response,
                });
              } else {
                return getSuperAdminDashboardRevenuDetials_BasedOn_DateFailure({
                  getSuperadmindashboardRevenueOnDateResponseError: response,
                });
              }
            }),
            catchError((error) => {
              console.log(error);
              this.sharedService.showMessageDialog(
                error.error.message,
                this.ServiceError.Error,
                SweetAlertIcon.ERROR
              );
              return of(
                getSuperAdminDashboardRevenuDetials_BasedOn_DateFailure({
                  getSuperadmindashboardRevenueOnDateResponseError: error,
                })
              );
            }),
            finalize(() => this.loaderService.setLoading(false))
          );
      })
    )
  );
}
